import React, { useEffect, useState } from "react";
import { MagnifyingGlass } from "react-loader-spinner";
import { Link, useNavigate } from "react-router-dom";
import ScrollToTop from "../ScrollToTop";
import { useRecoilState } from "recoil"; // Usamos Recoil para el estado
import { cartState } from "./cartState"; // Estado atómico de Recoil para el carrito
import { addressesState } from "./addressesState"; // Estado atómico de Recoil para las direcciones
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ShopCheckOut = () => {
  const [cart, setCart] = useRecoilState(cartState); // Usamos Recoil para acceder y actualizar el carrito
  const [addresses, setAddresses] = useRecoilState(addressesState); // Usamos Recoil para acceder y actualizar las direcciones
  const navigate = useNavigate();
  const [loaderStatus, setLoaderStatus] = useState(true);
  const [formData, setFormData] = useState({
    fullName: "",
    phone: "",
    street: "",
    number: "",
    city: "Celendín",
    district: "Celendín",
  });

  const [isOrderProcessing, setIsOrderProcessing] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoaderStatus(false);
    }, 1500);
  }, []);

  useEffect(() => {
    const savedAddresses = JSON.parse(localStorage.getItem("addresses")) || [];
    setAddresses(savedAddresses); // Restaurar direcciones
  }, [setAddresses]);

  const calculateTotal = () => {
    return cart.reduce((acc, product) => {
      const price = product.discountPrice || product.price;
      return acc + price * product.quantity;
    }, 0);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const addAddress = () => {
    if (!formData.fullName || !formData.phone) {
      alert("Por favor, complete todos los campos de la dirección.");
      return;
    }
    setAddresses([...addresses, formData]);
    localStorage.setItem("addresses", JSON.stringify([...addresses, formData]));
    setFormData({
      fullName: "",
      phone: "",
      street: "",
      number: "",
      city: "Celendín",
      district: "Celendín",
    });
  };

  const removeAddress = (index) => {
    const updatedAddresses = addresses.filter((_, i) => i !== index);
    setAddresses(updatedAddresses);
    localStorage.setItem("addresses", JSON.stringify(updatedAddresses));
  };

  const submitOrder = () => {
    if (!addresses[0]?.fullName || !addresses[0]?.phone) {
      alert("Por favor, complete todos los campos de la dirección.");
      return;
    }

    const phoneNumber = addresses[0]?.phone.startsWith("+51")
      ? addresses[0]?.phone
      : `+51${addresses[0]?.phone}`;

    const apiUrl = `${process.env.REACT_APP_API_URL}/orders`;
    const orderData = {
      products: cart.map((product) => ({
        productId: product.id,
        quantity: product.quantity,
      })),
      client: {
        fullName: addresses[0]?.fullName,
        phone: { number: phoneNumber },
        address: {
          street: addresses[0]?.street,
          number: addresses[0]?.number,
          city: addresses[0]?.city,
          district: addresses[0]?.district,
        },
      },
    };

    console.log("Datos del pedido que se van a enviar:", JSON.stringify(orderData, null, 2));

    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(orderData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Respuesta del servidor:", data);

        let orderDetailsMessage = "";
        let totalPrice = 0;
        //let serviceCharge = 3;

        data.forEach((order) => {
          const orderId = order?.orderId;

          if (orderId) {
            let productDetails = `Detalles de la Orden ID: ${orderId}\n`;
            let orderTotal = 0;

            order.products?.forEach((item) => {
              const productName = item?.productName || "Producto no disponible";
              const productPrice = item?.price || 0;
              const productQuantity = item?.quantity || 0;
              const productTotal = productPrice * productQuantity;

              orderTotal += productTotal;
              productDetails += `- ${productName} (Cantidad: ${productQuantity}) - S/. ${productTotal}\n`;
            });

            productDetails += `Costo de envío: A coordinar con el conductor\n`;
            //orderTotal += serviceCharge;
            productDetails += `Monto total por tienda: S/. ${orderTotal}\n`;

            orderDetailsMessage += productDetails + "\n";

            totalPrice += orderTotal;
          }
        });

        orderDetailsMessage += `\nMonto total final: S/. ${totalPrice}`;

        const fixedPhoneNumber = process.env.REACT_APP_PHONE_NUMBER;
        const whatsappUrl = `whatsapp://send?phone=51${fixedPhoneNumber}&text=${encodeURIComponent(orderDetailsMessage)}`;

        // Mostrar toast de éxito y redirigir a WhatsApp después de 3 segundos
        toast.success("Su pedido ha sido enviado exitosamente. Será redirigido a WhatsApp para continuar con el proceso.", {
          position: "top-center",
          autoClose: 4000,
        });

        setTimeout(() => {
          window.location.href = whatsappUrl;
          // Limpiar el carrito
          setCart([]); // Limpiar el carrito usando Recoil

          // Redirigir a la categoría actual después de enviar el pedido
          const currentCategoryId = localStorage.getItem("currentCategoryId");
          navigate(`/category/${currentCategoryId}`);
        }, 4000);
      })
      .catch((error) => {
        console.error("Error al enviar el pedido:", error);
        alert("Hubo un problema al enviar el pedido. Inténtelo nuevamente.");
      });
  };

  return (
    <div>
      {loaderStatus ? (
        <div className="loader-container">
          <MagnifyingGlass
            visible={true}
            height="100"
            width="100"
            ariaLabel="magnifying-glass-loading"
            wrapperStyle={{}}
            wrapperclassName="magnifying-glass-wrapper"
            glassColor="#c0efff"
            color="#1A80E5"
          />
        </div>
      ) : (
        <>
          <ScrollToTop />
          <section className="mb-lg-14 mb-8 mt-8">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <h1 className="fw-bold mb-0">Finalizar compra</h1>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-7 col-md-12">
                  {/* Dirección de envío */}
                  <div className="accordion accordion-flush" id="accordionFlushExample">
                    <div className="accordion-item py-4">
                      <div className="d-flex justify-content-between align-items-center">
                        <Link
                          to="#"
                          className="fs-5 text-inherit collapsed h4"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseOne"
                          aria-expanded="true"
                          aria-controls="flush-collapseOne"
                        >
                          <i className="feather-icon icon-map-pin me-1.5 text-muted" />
                          Agregar dirección de envío
                        </Link>
                        <Link
                          to="#"
                          className="btn btn-outline-primary btn-sm"
                          data-bs-toggle="modal"
                          data-bs-target="#addAddressModal"
                        >
                          Agregar una nueva dirección
                        </Link>
                      </div>

                      <div id="flush-collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionFlushExample">
                        <div className="mt-5">
                          <div className="row">
                            {/* Mostrar la última dirección agregada */}
                            {addresses.length > 0 ? (
                              addresses.map((address, index) => (
                                <div className="col-lg-6 col-12 mb-4" key={index}>
                                  <div className="border p-6 rounded-3">
                                    <div className="form-check mb-4">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name="flexRadioDefault"
                                        id={`homeRadio${index}`}
                                        defaultChecked
                                      />
                                      <label className="form-check-label text-dark" htmlFor={`homeRadio${index}`}>
                                        Casa
                                      </label>
                                    </div>
                                    <address>
                                      <strong>{address.fullName}</strong> <br />
                                      {address.street} {address.number}, <br />
                                      {address.city}, {address.district}, <br />
                                      <abbr title="Teléfono">
                                        P: {address.phone}
                                      </abbr>
                                    </address>
                                    <p
                                      className="text-danger"
                                      style={{
                                        cursor: "pointer",
                                        fontSize: "14px",
                                        marginTop: "5px",
                                      }}
                                      onClick={() => removeAddress(index)}
                                    >
                                      Eliminar
                                    </p>
                                  </div>
                                </div>
                              ))
                            ) : (
                              <p>Aún no se ha agregado ninguna dirección.</p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-md-12 offset-lg-1 col-lg-4">
                  <div className="mt-4 mt-lg-0">
                    <div className="card shadow-sm">
                      <h5 className="px-6 py-4 bg-transparent mb-0">Detalles del pedido</h5>
                      <ul className="list-group list-group-flush">
                        {/* Detalle de los productos */}
                        {cart.map((product, index) => (
                          <li className="list-group-item px-4 py-3" key={index}>
                            <div className="row align-items-center">
                              <div className="col-2 col-md-2">
                                <img src={product.imageUrl} alt={product.name} className="img-fluid" />
                              </div>
                              <div className="col-5 col-md-5">
                                <h6 className="mb-0">{product.name}</h6>
                                <span>
                                  {product.discountPrice ? (
                                    <>
                                      <small
                                        className="text-muted"
                                        style={{ textDecoration: "line-through" }}
                                      >
                                        S/ {product.price}
                                      </small>
                                      <span className="text-danger">
                                        {" "} S/ {product.discountPrice}
                                      </span>
                                    </>
                                  ) : (
                                    <small className="text-muted">S/ {product.price}</small>
                                  )}
                                </span>
                              </div>
                              <div className="col-2 col-md-2 text-center text-muted">
                                <span>{product.quantity}</span>
                              </div>
                              <div className="col-3 text-lg-end text-start text-md-end col-md-3">
                                <span className="fw-bold">
                                  S/. {product.discountPrice ? product.discountPrice * product.quantity : product.price * product.quantity}
                                </span>
                              </div>
                            </div>
                          </li>
                        ))}

                        <li className="list-group-item px-4 py-3">
                          <div className="d-flex align-items-center justify-content-between mb-2">
                            <div>Subtotal de los artículos</div>
                            <div className="fw-bold">S/. {calculateTotal()}</div>
                          </div>
                          <div className="d-flex align-items-center justify-content-between">
                            <div>Costo de envío</div>
                            <div className="fw-bold">A coordinar con el conductor</div>
                          </div>
                        </li>
                        <li className="list-group-item px-4 py-3">
                          <div className="d-flex align-items-center justify-content-between fw-bold">
                            <div>Total</div>
                            <div>S/. {calculateTotal()}</div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="d-grid mb-1 mt-3">
                      <button
                        className="btn btn-primary btn-lg d-flex justify-content-between align-items-center"
                        type="button"
                        onClick={submitOrder}
                      >
                        Enviar Pedido
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* Modal para agregar una nueva dirección */}
          <div
            className="modal fade"
            id="addAddressModal"
            tabIndex={-1}
            aria-labelledby="addAddressModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="addAddressModalLabel">
                    Nueva dirección de envío
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  />
                </div>
                <div className="modal-body p-6">
                  <div className="d-flex justify-content-between mb-5">
                    <div>
                      <p className="small mb-0">Añadir una nueva dirección de envío para tu pedido.</p>
                    </div>
                  </div>
                  <div className="row g-3">
                    <div className="col-12">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Nombre completo"
                        aria-label="Nombre completo"
                        name="fullName"
                        value={formData.fullName}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className="col-12">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Número de teléfono"
                        aria-label="Número de teléfono"
                        name="phone"
                        value={formData.phone}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className="col-12">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Calle"
                        aria-label="Calle"
                        name="street"
                        value={formData.street}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="col-12">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Número"
                        aria-label="Número"
                        name="number"
                        value={formData.number}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="col-12">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Ciudad"
                        aria-label="Ciudad"
                        name="city"
                        value={formData.city}
                        disabled
                      />
                    </div>
                    <div className="col-12">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Distrito"
                        aria-label="Distrito"
                        name="district"
                        value={formData.district}
                        disabled
                      />
                    </div>
                    <div className="col-12 text-end">
                      <button
                        type="button"
                        className="btn btn-outline-primary"
                        data-bs-dismiss="modal"
                      >
                        Cancelar
                      </button>
                      <button
                        className="btn btn-primary"
                        type="button"
                        data-bs-dismiss="modal"
                        onClick={addAddress}
                      >
                        Guardar dirección
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
        </>
      )}
    </div>
  );
};

export default ShopCheckOut;
