import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { cartState, addToCart } from "./cartState";

export const ProductDetail = ({ show, handleClose, product = null }) => {
  const [quantity, setQuantity] = useState(1);
  const [cart, setCart] = useRecoilState(cartState);
  const navigate = useNavigate();

  const increaseQuantity = () => setQuantity((prev) => prev + 1);
  const decreaseQuantity = () => {
    if (quantity > 1) setQuantity((prev) => prev - 1);
  };

  const modalStyles = {
    display: show ? "block" : "none",
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1050,
    overflowX: "hidden",
    overflowY: "auto",
    outline: 0,
  };

  const backdropStyle = {
    display: show ? "block" : "none",
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 1040,
  };

  // Función para agregar producto y cerrar modal
  const addAndKeep = () => {
    setCart((prevCart) => {
      const updatedCart = addToCart(prevCart, { ...product, quantity });
      localStorage.setItem("cart", JSON.stringify(updatedCart));
      return updatedCart;
    });
    handleClose();
  };

  // Función para agregar producto y redirigir al carrito
  const addAndPay = () => {
    setCart((prevCart) => {
      const updatedCart = addToCart(prevCart, { ...product, quantity });
      localStorage.setItem("cart", JSON.stringify(updatedCart));
      return updatedCart;
    });
    handleClose();
    navigate("/shopcart");
  };

  return (
    <>
      {/* Backdrop */}
      <div
        className={`modal-backdrop fade ${show ? "show" : ""}`}
        style={backdropStyle}
        onClick={handleClose}
      ></div>

      {/* Modal Container */}
      <div
        className={`modal fade ${show ? "show" : ""}`}
        style={modalStyles}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="productDetailModalLabel"
        aria-hidden={!show}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title w-100 text-center"
                id="productDetailModalLabel"
              >
                <strong>{product?.name || "Name"}</strong>
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={handleClose}
                aria-label="Close"
              ></button>
            </div>

            <div className="modal-body">
              <div className="container-fluid">
                <div className="row">
                  {/* Image (Left on Desktop, Top on Mobile) */}
                  <div className="col-12 col-md-6">
                    <img
                      src={product?.imageUrl || ""}
                      alt={product?.name || "Imagen de producto"}
                      className="img-fluid rounded"
                      style={{ width: "100%", height: "auto" }}
                    />
                  </div>

                  {/* Details (Right on Desktop, Below on Mobile) */}
                  <div className="col-12 col-md-6">
                    <p>{product?.description || "Description"}</p>

                    {/* Quantity Selector */}
                    <div className="d-flex align-items-center justify-content-center justify-content-md-start mt-3">
                      <span className="me-2">Unidades:</span>
                      <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        onClick={decreaseQuantity}
                      >
                        -
                      </button>
                      <span className="mx-2">{quantity}</span>
                      <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        onClick={increaseQuantity}
                      >
                        +
                      </button>
                    </div>

                    {/* Price */}
                    <div className="mt-3 text-center text-md-start">
                      {/* Center on mobile, left on desktop */}
                      <span className="text-dark me-2">
                        {`S./ ${product?.price?.toFixed(2)}`}
                      </span>
                      {product?.discountPrice && (
                        <span className="fw-bold text-decoration-line-through text-danger">
                          {`S./ ${product.discountPrice.toFixed(2)}`}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-footer justify-content-center">
              <button
                type="button"
                className="btn btn-primary me-2"
                onClick={addAndKeep}
              >
                Agregar y seguir
              </button>
              <button
                type="button"
                className="btn btn-success"
                onClick={addAndPay}
              >
                Agregar y pagar
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
