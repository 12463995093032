import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

const GuideShop = () => {
  return (
    <div className="container mt-5">
      <h2 className="text-center mb-4">GUÍA DE COMPRA</h2>
      <p className="text-center">Fácil y seguro, en sólo 6 simples pasos</p>
      <p>Comprar es muy fácil, solo debes seguir estos pasos:</p>

      <div className="mb-5">
        <h4>1. Encuentra tu producto:</h4>
        <p>
          ¡Tenemos la tecnología que necesitas, sin salir de casa! Para
          encontrar el producto que deseas, puedes utilizar nuestro buscador o
          el menú de categorías.
        </p>
        <img
          src="URL_DE_LA_IMAGEN_1"
          alt="Encuentra tu producto"
          className="img-fluid"
        />
      </div>

      <div className="mb-5">
        <h4>2. Añade tu producto a tu Carrito de compra</h4>
        <p>
          Cuando hayas encontrado tu producto, haz clic en “AGREGAR AL CARRO”.
          Selecciona la cantidad de unidades que desees comprar y luego dale
          clic a “IR A PAGAR”.
        </p>
        <img
          src="URL_DE_LA_IMAGEN_2"
          alt="Añade al carrito"
          className="img-fluid"
        />
      </div>

      <div className="mb-5">
        <h4>3. Confirma tu Carrito de compra</h4>
        <p>
          Verifica los productos que seleccionaste y los descuentos aplicados en
          el caso de existir alguna promoción. Si está todo conforme, haz clic a
          “IR A PAGAR”.
        </p>
        <img
          src="URL_DE_LA_IMAGEN_3"
          alt="Confirma tu carrito"
          className="img-fluid"
        />
      </div>

      <div className="mb-5">
        <h4>4. Inicia sesión o Crea una cuenta</h4>
        <p>
          Si ya eres usuario, inicia sesión. Si no estás registrado, también
          podrás realizar tu compra. Sin embargo, te recordamos que
          registrándote tendrás los siguientes beneficios:
        </p>
        <ul>
          <li>Comprar fácil y rápido donde te encuentres.</li>
          <li>Acceder a Promociones Exclusivas online.</li>
          <li>Verificar el estado de tus órdenes de compra.</li>
        </ul>
        <img
          src="URL_DE_LA_IMAGEN_4"
          alt="Inicia sesión o crea una cuenta"
          className="img-fluid"
        />
      </div>

      <div className="mb-5">
        <h4>5. Ingresa los datos de envío y elige método de pago</h4>
        <p>
          Si ya estás registrado, aquí podrás elegir alguna de tus direcciones o
          ingresar una nueva. Si aún no lo estás, ingresa la dirección donde
          deseas que se envíe tu compra.
        </p>
        <p>
          Ahora, elige tu medio de pago. Te ofrecemos las siguientes opciones:
          Tarjetas Visa, MasterCard, American Express y Diners Club, así como
          PagoEfectivo (para pagos en agentes y principales bancas por
          internet). Luego de elegir el medio de pago y aceptar los términos y
          condiciones, serás redirigido a la página de MercadoPago donde podrás
          ingresar los datos de pago correspondiente en un ambiente encriptado y
          100% seguro.
        </p>
        <img
          src="URL_DE_LA_IMAGEN_5"
          alt="Datos de envío y método de pago"
          className="img-fluid"
        />
      </div>

      <div className="mb-5">
        <h4>6. Finaliza tu compra</h4>
        <p>
          ¡Listo! ¡Felicidades! Realizaste con éxito tu compra y tu pedido ya
          está registrado en nuestro sistema. Podrás ver tu orden de compra y
          recibirás un correo electrónico de confirmación.
        </p>
        <p>
          Recuerda que si elegiste pagar en Agentes o Banca por Internet, el
          código de pago que te brindamos tiene una validez de 7 días.
        </p>
        <img
          src="URL_DE_LA_IMAGEN_6"
          alt="Finaliza tu compra"
          className="img-fluid"
        />
      </div>

      <p>
        Recuerda que podrás rastrear la ubicación de nuestro camión de despacho
        en tiempo real y conocer el momento exacto de la entrega sin necesidad
        de estar registrado y solo con tu correo y número de orden. Solo debes
        ingresar a “RASTREAR TU ORDEN”.
      </p>
    </div>
  );
};

export default GuideShop;
