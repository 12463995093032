import React from "react";
import { Link } from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.min.css";
import logo from "../img/altoke-white.png";

const Footer = () => {
  let date = new Date();
  let year = date.getFullYear();
  return (
    <>
      <footer className="footer" style={{ backgroundColor: "black" }}>
        <div className="overlay" />
        <div className="container">
          <div className="row footer-row">
            <div className="col-sm-6 col-lg-3 mb-30">
              <div className="footer-widget">
                <div className="footer-logo">
                  <Link to="/">
                    <img
                      src={logo}
                      style={{ width: 300, padding: 20, marginLeft: "-30px" }}
                      alt="logo"
                    />
                  </Link>
                </div>
              </div>
              <div className="dimc-protect">
                <div className="col-lg-5 text-lg-start text-center mb-2 mb-lg-0">
                  <ul className="list-inline d-flex mb-0">
                    <li className="list-inline-item"></li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-3 mb-30">
              <div className="footer-widget mb-0">
                <div className="line-footer" />
                <div className="row">
                  <div className="col">
                    <ul className="footer-link mb-0">
                      <li>
                        <Link to="/AboutUs" style={{ color: "white" }}>
                          <span>
                            <i className="fa fa-angle-right" />
                          </span>
                          {""}
                          Acerca de Altoke
                        </Link>
                      </li>
                      <li>
                        <a
                          href="https://forms.gle/esPPM1Hbbn2eR9hz8"
                          style={{ color: "white" }}
                          target="_blank"
                        >
                          <span>
                            <i className="fa fa-angle-right" />
                          </span>{" "}
                          Conviértete en Altoke Aliado
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://forms.gle/pigZzncFwwgCQBGf9"
                          style={{ color: "white" }}
                          target="_blank"
                        >
                          <span>
                            <i className="fa fa-angle-right" />
                          </span>{" "}
                          Trabaja como Conductor
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-3 mb-30">
              <div className="footer-widget mb-0">
                <div className="line-footer" />
                <div className="row">
                  <div className="col">
                    <ul className="footer-link mb-0">
                      <li>
                        <Link to="/Contact" style={{ color: "white" }}>
                          <span>
                            <i className="fa fa-angle-right" />
                          </span>{" "}
                          Términos y Condiciones
                        </Link>
                      </li>
                      <li>
                        <Link to="/Contact" style={{ color: "white" }}>
                          <span>
                            <i className="fa fa-angle-right" />
                          </span>{" "}
                          Política de Privacidad
                        </Link>
                      </li>
                      <li>
                        <Link to="#" style={{ color: "white" }}>
                          <span>
                            <i className="fa fa-angle-right" />
                          </span>
                          Disponible en Celendín
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-3 mb-30">
              <div className="footer-widget mb-0">
                <div className="line-footer" />
                <div className="row">
                  <div className="col">
                    <ul className="footer-link mb-0">
                      <li>
                        <Link to="/GuideShop" style={{ color: "white" }}>
                          <span>
                            <i className="fa fa-angle-right" />
                          </span>{" "}
                          ¿Cómo comprar?
                        </Link>
                      </li>
                      <li>
                        <Link to="/AboutUs" style={{ color: "white" }}>
                          <span>
                            <i className="fa fa-angle-right" />
                          </span>{" "}
                          Contacta con nosotros
                        </Link>
                      </li>
                      <li>
                        <Link to="/StoreList" style={{ color: "white" }}>
                          <span>
                            <i className="fa fa-angle-right" />
                          </span>{" "}
                          Restaurantes Destacados
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="footer-widget mt-8">
                <ul
                  className="social-media"
                  style={{ display: "flex", gap: 10 }}
                >
                  <li>
                    <a
                      href="https://www.facebook.com/people/Altoke-Driver/61571824696003/"
                      className="facebook "
                      target="_blank"
                    >
                      <i className="bx bxl-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://wa.me/message/PHHCGHI2X54BO1"
                      className="whatsapp"
                      target="_blank"
                    >
                      <i className="bx bxl-whatsapp"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/altoke.driver?igsh=NmdhcmN1dWtwamhy"
                      className="instagram "
                      target="_blank"
                    >
                      <i className="bx bxl-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.tiktok.com/@altokedriver"
                      target="_blank"
                      className="tiktok"
                    >
                      <i className="bx bxl-tiktok"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bar ">
          <div className="container text-center fw-bold text-white">
            <div className="footer-copy">
              <div className="copyright">
                © {year} ALTOKE Todos los derechos reservados | Desarrollado por{" "}
                <a
                  className="text-decoration-none"
                  href="https://github.com/Darkness0550"
                  target="_blank"
                >
                  Driveon Corp
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
