import React from "react";
import Lottie from "lottie-react";
import animationData from "../../animation/Error404.json";
import { Link } from "react-router-dom";

const Error404 = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        backgroundColor: "#f8f9fa",
        textAlign: "center",
      }}
    >
      <div style={{ width: "30%" }}>
        <Lottie animationData={animationData} loop={true} />
      </div>
      <h1 style={{ marginTop: "20px", fontWeight: "bold", color: "#dc3545" }}>
        ¡Página no encontrada!
      </h1>
      <p style={{ color: "#6c757d" }}>
        Lo sentimos, la página que buscas no existe.
      </p>
      <Link
        to="/"
        style={{
          marginTop: "15px",
          padding: "10px 20px",
          backgroundColor: "#007bff",
          color: "white",
          textDecoration: "none",
          borderRadius: "5px",
          fontWeight: "bold",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        }}
      >
        Volver al inicio
      </Link>
    </div>
  );
};

export default Error404;
