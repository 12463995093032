import { useQuery } from "react-query";
import { getCategories, getCategoryProducts } from "../api/categories.service";

export const useGetCategories = () => {
  return useQuery({
    queryFn: getCategories,
    queryKey: ["categories"],
  });
};

export const useGetGategory = (id = 0, products = true) => {
  return useQuery({
    queryFn: () => getCategoryProducts(id, products),
    queryKey: ["category", id, { includeProducts: products }],
    enabled: !!id,
    staleTime: 1000 * 5 * 60,
    retry: 2,
  });
};
