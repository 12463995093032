import React, { useEffect, useState } from "react";

const TermsModal = () => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const accepted = localStorage.getItem("acceptedTerms");
    if (!accepted) {
      setShowModal(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem("acceptedTerms", "true");
    setShowModal(false);
  };

  if (!showModal) return null;

  return (
    <div className="modal fade show d-block" tabIndex="-1" style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}>
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Términos y Condiciones de Uso de Altoke</h5>
          </div>
          <div className="modal-body" style={{ maxHeight: "60vh", overflowY: "auto" }}>
            <h6>1. Introducción</h6>
            <p>
              Altoke es una plataforma digital que facilita la conexión entre usuarios y negocios locales para
              la adquisición de productos y servicios de manera sencilla y rápida. A través de la plataforma,
              los usuarios pueden explorar diferentes negocios, seleccionar productos y coordinar su compra
              y entrega mediante un canal de comunicación directa con Altoke.
            </p>
            <p>
              Al acceder y utilizar la plataforma, los usuarios aceptan los presentes Términos y Condiciones
              de Uso. Si no está de acuerdo con los mismos, le recomendamos abstenerse de utilizar
              nuestros servicios.
            </p>

            <h6>2. Definiciones</h6>
            <ul>
              <li><strong>Altoke:</strong> Plataforma digital que conecta negocios locales con usuarios para la compra y envío de productos.</li>
              <li><strong>Usuario:</strong> Persona que accede y utiliza la plataforma para explorar y adquirir productos.</li>
              <li><strong>Negocio Afiliado:</strong> Comercios registrados en la plataforma que ofrecen sus productos.</li>
              <li><strong>Pedido:</strong> Solicitud realizada por un usuario para adquirir productos de uno o varios negocios afiliados.</li>
            </ul>

            <h6>3. Funcionamiento de la Plataforma</h6>
            <p>
              Altoke permite a los usuarios navegar libremente por la plataforma, explorar productos y realizar pedidos sin necesidad de registro.
              Para concretar una compra, el usuario deberá proporcionar su nombre completo, número de teléfono y dirección de entrega.
              Una vez enviado el pedido a través de WhatsApp, Altoke coordina con el negocio afiliado para procesar el pedido, definir el pago y organizar la entrega.
              El costo del envío puede variar según la distancia y el horario y será informado antes de completar la compra.
            </p>

            <h6>4. Responsabilidad de Altoke</h6>
            <p>
              Altoke actúa como un intermediario entre los negocios afiliados y los usuarios, y no fabrica ni almacena productos.
              La información de productos, precios e imágenes es proporcionada por los negocios afiliados.
              Aunque Altoke vela por la gestión adecuada, no puede garantizar disponibilidad o calidad de productos.
              En caso de inconvenientes, Altoke gestionará soluciones con el negocio correspondiente.
            </p>

            <h6>5. Venta y Consumo de Bebidas Alcohólicas y Productos Restringidos</h6>
            <p>
              Altoke cumple con la normativa legal sobre venta de bebidas alcohólicas, tabaco y medicamentos.
              Se solicitará fotografía del DNI antes del pago y se exigirá acreditación de mayoría de edad al momento de la entrega.
              El incumplimiento por parte de un negocio afiliado puede llevar a su suspensión de la plataforma.
            </p>

            <h6>6. Cancelaciones y Reembolsos</h6>
            <p>
              Los pedidos pueden cancelarse en circunstancias excepcionales.
              Si el usuario recibe productos erróneos, en mal estado o no recibe su pedido, puede presentar un reclamo.
              Altoke mediará para determinar si corresponde un reembolso total, parcial o reposición.
              Los reembolsos dependerán de cada caso y serán verificados con el negocio afiliado.
            </p>

            <h6>7. Capacidad</h6>
            <p>
              Los servicios de Altoke están disponibles solo para mayores de edad con capacidad legal.
              Para registrar una persona jurídica, se debe contar con la representación legal correspondiente.
            </p>

            <h6>8. Promociones, Concursos y Eventos</h6>
            <p>
              Altoke podrá ofrecer promociones, concursos y sorteos con términos específicos.
              También podrá enviar publicidad o muestras promocionales con el consentimiento del usuario.
            </p>

            <h6>9. Uso de Cupones y Descuentos</h6>
            <p>
              Los cupones otorgados solo se usan en negocios adheridos de la plataforma.
              No son canjeables por dinero, no se acumulan y tienen plazo de vigencia.
              Si un pedido con cupón se cancela, este podría reactivarse dentro de 24 horas.
            </p>

            <h6>10. Derechos de Autor y Propiedad Intelectual</h6>
            <p>
              El contenido de la plataforma está protegido por derechos de autor.
              Está prohibido copiar, modificar o distribuir contenido sin autorización expresa.
            </p>

            <h6>11. Modificaciones a los Términos y Condiciones</h6>
            <p>
              Altoke puede modificar estos términos en cualquier momento.
              Los cambios se informarán a través de la plataforma y entrarán en vigencia desde su publicación.
              El uso continuo de la plataforma implica la aceptación de las modificaciones.
            </p>

            <h6>Política de Privacidad</h6>
            <p>
              Altoke recopila solo la información necesaria para procesar pedidos: nombre completo, teléfono y dirección.
              Esta información no se comparte ni vende a terceros, salvo por obligación legal.
              Se almacenan los datos de forma segura y se pueden eliminar a solicitud.
              Se usan cookies para mejorar la experiencia del usuario. Pueden desactivarse desde el navegador, aunque podría afectar el funcionamiento del sitio.
              Si un usuario desea eliminar sus datos o tiene dudas, puede contactar con el equipo de soporte.
            </p>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-primary" onClick={handleAccept}>
              Aceptar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsModal;
