import { useQuery } from "react-query";
import { getBusinesses, getOneBusiness } from "../api/business.service";

export const useGetBusiness = ({ businessId, includeProducts = false }) => {
  return useQuery({
    queryFn: () => getOneBusiness({ businessId, includeProducts }),
    queryKey: ["business", businessId],
    staleTime: 1 * 1000 * 60,
  });
};

export const useGetBusinesses = () => {
  return useQuery({
    queryFn: getBusinesses,
    queryKey: ["business"],
    staleTime: 2 * 1000 * 60,
  });
};
