import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
import { MagnifyingGlass } from "react-loader-spinner";
import ScrollToTop from "../ScrollToTop";

const Contact = () => {
  // loading
  const [loaderStatus, setLoaderStatus] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoaderStatus(false);
    }, 1500);
  }, []);

  return (
    <div>
      <div>
        {loaderStatus ? (
          <div className="loader-container">
            {/* <PulseLoader loading={loaderStatus} size={50} color="#1A80E5" /> */}
            <MagnifyingGlass
              visible={true}
              height="100"
              width="100"
              ariaLabel="magnifying-glass-loading"
              wrapperStyle={{}}
              wrapperclassName="magnifying-glass-wrapper"
              glassColor="#c0efff"
              color="#1A80E5"
            />
          </div>
        ) : (
          <>
            <>
              <ScrollToTop />
            </>
            <>
              {/* section */}
              <section className="my-lg-14 my-8">
                {/* container */}
                <div className="container">
                  <div className="row">
                    {/* col */}
                    <div className="offset-lg-2 col-lg-8 col-12">
                      <div className="mb-8">
                        <h1 className="h3">
                          Términos y Condiciones y Políticas de Privacidad de
                          Altoke
                        </h1>
                        {/* heading */}
                        <h1 className="h3">Introducción</h1>
                        <p className="lead mb-0">
                          Bienvenido a Altoke, una plataforma de taxi y delivery
                          que permite a los usuarios acceder a diversos
                          servicios, incluyendo transporte urbano, envíos de
                          paquetes y delivery de productos y alimentos de
                          negocios afiliados. Al utilizar nuestra plataforma, el
                          usuario acepta los términos y condiciones aquí
                          establecidos, así como nuestras políticas de
                          privacidad.
                        </p>
                        <h1 className="h3">Funcionamiento de la Plataforma</h1>
                        <p className="lead mb-0">
                          Altoke facilita la visualización de productos y
                          servicios de distintos negocios. Los clientes pueden
                          navegar sin necesidad de registro, seleccionar
                          productos, agregarlos a su carrito y enviar su pedido
                          a través de WhatsApp para coordinar el pago y la
                          entrega. El equipo de Altoke se encarga de gestionar
                          la coordinación del pago, el cual deberá ser realizado
                          por adelantado para evitar cancelaciones. Asimismo,
                          Altoke organiza la entrega del pedido mediante su
                          servicio de delivery. Para completar la entrega, se
                          solicitarán los siguientes datos del cliente: Nombre
                          completo Número de teléfono Dirección de entrega
                        </p>
                        <h1 className="h3">Datos de los Negocios</h1>
                        <p className="lead mb-0">
                          Los negocios afiliados proporcionan la información de
                          sus productos, incluyendo: Nombre del negocio Logo
                          Descripción Categorías Dirección Listado de productos
                          (nombre, foto, descripción, precio, descuentos y
                          stock) Altoke no se responsabiliza por la veracidad de
                          la información proporcionada por los negocios,
                          incluyendo imágenes y descripciones de productos, que
                          pueden ser referenciales o reales.
                        </p>
                        <h1 className="h3">Venta de Bebidas Alcohólicas</h1>
                        <p className="lead mb-0">
                          Altoke permite la visualización y comercialización de
                          bebidas alcohólicas dentro de la plataforma. Sin
                          embargo, la venta y entrega de estos productos están
                          sujetas a la legislación vigente en cada jurisdicción.
                          Altoke no se responsabiliza por la distribución de
                          bebidas alcohólicas a menores de edad ni por el uso
                          indebido de estos productos por parte de los
                          clientes.{" "}
                        </p>
                        <h1 className="h3">Limitación de Responsabilidad</h1>
                        <p className="lead mb-0">
                          Altoke actúa únicamente como intermediario entre
                          negocios y clientes. No nos hacemos responsables por:
                          La calidad, estado o disponibilidad de los productos
                          ofrecidos por los negocios afiliados. Errores en la
                          información proporcionada por los negocios. Retrasos o
                          inconvenientes en la entrega causados por factores
                          externos. Problemas derivados del incumplimiento de
                          normativas por parte de los negocios afiliados.
                        </p>
                        <h1 className="h3">Protección de Datos y Privacidad</h1>
                        <p className="lead mb-0">
                          Altoke se compromete a proteger la privacidad de los
                          usuarios y negocios afiliados. La información
                          recopilada será utilizada exclusivamente para la
                          prestación del servicio y no será compartida con
                          terceros sin consentimiento expreso.
                        </p>
                        <h1 className="h3">
                          Modificaciones a los Términos y Condiciones
                        </h1>
                        <p className="lead mb-0">
                          Altoke se reserva el derecho de modificar estos
                          términos y condiciones en cualquier momento. Los
                          cambios serán notificados oportunamente a los usuarios
                          y negocios afiliados a través de la plataforma. Al
                          utilizar Altoke, el usuario acepta y reconoce haber
                          leído estos términos y condiciones, así como las
                          políticas de privacidad aquí establecidas.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </>
          </>
        )}
      </div>
    </div>
  );
};

export default Contact;
