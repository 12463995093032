import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../img/altoke_logo.png";
import { FaSearch } from "react-icons/fa";
import { FaCartShopping } from "react-icons/fa6";
import { IoStorefront } from "react-icons/io5";
import { BiSolidCategoryAlt } from "react-icons/bi";
import { CartModal } from "./CartModal";
import { useGetCategories } from "../hooks/categories-hooks";
import { useRecoilValue } from "recoil";
import { cartState } from "../pages/Shop/cartState";

function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Estado del carrito
  const cart = useRecoilValue(cartState);
  const cartCount = cart.length;

  // Alternar modal del carrito
  const handleCartClick = () => {
    setIsModalOpen(!isModalOpen);
  };

  const closeCartModal = () => {
    setIsModalOpen(false);
  };

  // Alternar navbar (menú hamburguesa)
  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  // Cerrar solo navbar (cuando haces clic en un link móvil)
  const handleMobileNavLinkClick = () => {
    setIsOpen(false);
  };

  const { data: categories, isLoading, error } = useGetCategories();

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-light mx-2 mt-2">
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img src={logo} alt="Altoke Logo" style={{ height: 60 }} />
          </Link>

          {/* Botón carrito móvil */}
          <div className="d-lg-none position-relative">
            <button
              className="bg-transparent"
              type="button"
              style={{ border: "none" }}
              onClick={() => setIsModalOpen(true)}
            >
              <FaCartShopping className="mx-3" style={{ fontSize: "1.25rem" }} />
              Mi carrito
              {cartCount > 0 && (
                <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                  {cartCount}
                  <span className="visually-hidden">
                    productos en el carrito
                  </span>
                </span>
              )}
            </button>
          </div>

          {/* Botón hamburguesa */}
          <div className="navbar-toggler-container">
            <button
              className="navbar-toggler"
              type="button"
              onClick={toggleNavbar}
              aria-label="Toggle navigation"
              style={{ border: "none" }}
            >
              <span className="navbar-toggler-icon"></span>
            </button>
          </div>

          {/* Contenido principal */}
          <div className="d-flex justify-content-between align-items-center w-100 mx-3">
            {/* Búsqueda desktop
            <form className="d-none d-lg-flex w-50">
              <div className="input-group">
                <input
                  className="form-control"
                  type="search"
                  placeholder="Escribe tu búsqueda..."
                  aria-label="Search"
                />
                <button className="btn btn-outline-primary" type="submit">
                  <FaSearch />
                </button>
              </div>
            </form> */}

            {/* Menú colapsable */}
            <div
              className={`collapse navbar-collapse ${isOpen ? "show" : ""}`}
              id="navbarNav"
            >
              <ul className="navbar-nav ms-auto">
                <li className="nav-item">
                  <Link
                    className="nav-link d-flex align-items-center"
                    to="/storelist"
                    onClick={handleMobileNavLinkClick}
                  >
                    <IoStorefront className="me-2" style={{ fontSize: "1.25rem" }} />
                    Lista de tiendas
                  </Link>
                </li>

                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle d-flex align-items-center"
                    to="#"
                    id="navbarDropdownMenuLink"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded={isOpen ? "true" : "false"}
                  >
                    <BiSolidCategoryAlt
                      className="me-2"
                      style={{ fontSize: "1.25rem" }}
                    />
                    Categorías
                  </Link>
                  
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdownMenuLink"
                  >
                    {isLoading && (
                      <li>
                        <span className="dropdown-item">
                          Cargando categorías...
                        </span>
                      </li>
                    )}
                    {error && (
                      <li>
                        <span className="dropdown-item text-danger">
                          Error al cargar categorías
                        </span>
                      </li>
                    )}
                    {categories &&
                      !isLoading &&
                      categories.length > 0 &&
                      categories.map((category, index) => (
                        <li key={index}>
                          <Link
                            className="dropdown-item"
                            to={`/category/${category?.id}`}
                            onClick={handleMobileNavLinkClick}
                          >
                            {category?.name}
                          </Link>
                        </li>
                      ))}
                  </ul>
                </li>

                <li className="nav-item d-lg-block d-none position-relative">
                  <button
                    className="nav-link d-flex align-items-center"
                    onClick={handleCartClick}
                  >
                    <FaCartShopping className="me-2" style={{ fontSize: "1.1rem" }} />
                    Carrito
                    {cartCount > 0 && (
                      <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                        {cartCount}
                        <span className="visually-hidden">
                          productos en el carrito
                        </span>
                      </span>
                    )}
                  </button>
                </li>
              </ul>
            </div>
          </div>

        </div>
      </nav>

      {/* Modal del carrito */}
      <CartModal isModalOpen={isModalOpen} closeModal={closeCartModal} />
    </>
  );
}

export default Header;
