import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { cartState, removeFromCart, updateQuantity } from "../pages/Shop/cartState"; // Importa las operaciones

export const CartModal = ({ isModalOpen = false, closeModal }) => {
  const [cart, setCart] = useRecoilState(cartState); // Usamos Recoil para obtener y actualizar el carrito
  const navigate = useNavigate();

  const removeItem = (id) => {
    const updatedCart = removeFromCart(cart, id); // Filtramos el carrito y eliminamos el producto
    setCart(updatedCart); // Actualizamos el estado con el carrito modificado
  };

  const goToPay = () => {
    navigate("/ShopCheckOut");
    closeModal();
  };

  const handleQuantityChange = (id, operation) => {
    const updatedCart = updateQuantity(cart, id, operation === "increment" ? 1 : -1);
    setCart(updatedCart); // Actualizamos el carrito con la nueva cantidad
  };

  const calculateTotal = () => {
    return cart.reduce(
      (acc, product) =>
        acc + (product.discountPrice || product.price) * product.quantity,
      0
    );
  };

  return (
    <div
      className={`offcanvas offcanvas-end mx-2 ${isModalOpen ? "show" : ""}`}
      tabIndex={-1}
      id="offcanvasRight"
      aria-labelledby="offcanvasRightLabel"
      style={{ visibility: isModalOpen ? "visible" : "hidden" }}
    >
      <div className="offcanvas-header border-bottom">
        <div className="text-start">
          <h5 id="offcanvasRightLabel" className="mb-0 fs-4">
            Carrito de Compras
          </h5>
        </div>
        <button
          type="button"
          className="btn-close text-reset"
          onClick={closeModal}
          aria-label="Close"
        />
      </div>
      <div className="offcanvas-body">
        <div>
          <div className="py-3">
            <ul className="list-group list-group-flush">
              {cart.length > 0 ? (
                cart.map((product) => (
                  <li
                    className="list-group-item py-3 px-0 border-top"
                    key={product.id}
                  >
                    <div className="row align-items-center">
                      <div className="col-3 col-md-2">
                        <img
                          src={product.imageUrl}
                          alt={product.name}
                          className="img-fluid"
                        />
                      </div>
                      <div className="col-9 col-md-6">
                        <h6 className="mb-0">{product.name}</h6>
                        <span>
                          {product.discountPrice ? (
                            <>
                              <small
                                className="text-muted"
                                style={{ textDecoration: "line-through" }}
                              >
                                S/ {product.price.toFixed(2)}
                              </small>
                              <span className="text-danger">
                                {" "}
                                S/ {product.discountPrice.toFixed(2)}
                              </span>
                            </>
                          ) : (
                            <small className="text-muted">
                              S/ {product.price.toFixed(2)}
                            </small>
                          )}
                        </span>
                        <div className="mt-2 small">
                          <Link
                            to="#!"
                            className="text-decoration-none"
                            onClick={() => removeItem(product.id)}
                          >
                            <span className="me-1">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={16}
                                height={16}
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-trash-2"
                              >
                                <polyline points="3 6 5 6 21 6" />
                                <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
                                <line x1={10} y1={11} x2={10} y2={17} />
                                <line x1={14} y1={11} x2={14} y2={17} />
                              </svg>
                            </span>
                            Eliminar
                          </Link>
                        </div>
                      </div>
                      <div className="col-md-3 col-12 mt-3 mt-md-0">
                        <div className="input-group flex-nowrap justify-content-center justify-content-md-start">
                          <input
                            type="button"
                            value="-"
                            className="button-minus form-control text-center"
                            onClick={() =>
                              handleQuantityChange(product.id, "decrement")
                            }
                          />
                          <input
                            type="number"
                            inputMode="numeric"
                            pattern="[0-9]*"
                            min={1}
                            step={1}
                            max={10}
                            value={product.quantity}
                            className="quantity-field form-control text-center"
                            style={{ minWidth: "50px" }}
                            readOnly
                          />
                          <input
                            type="button"
                            value="+"
                            className="button-plus form-control text-center"
                            onClick={() =>
                              handleQuantityChange(product.id, "increment")
                            }
                          />
                        </div>
                        <div className="text-end mt-2 mt-md-0">
                          <span className="fw-bold">
                            S/.{" "}
                            {(
                              (product.discountPrice ?? product.price) *
                              product.quantity
                            ).toFixed(2)}
                          </span>
                        </div>
                      </div>
                    </div>
                  </li>
                ))
              ) : (
                <p>No hay productos en el carrito</p>
              )}
            </ul>
          </div>

          <div className="mt-4">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <div>Subtotal de los productos:</div>
              <span>S/ {calculateTotal().toFixed(2)}</span>
            </div>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <div>Costo de envío:</div>
              <span>A coordinar con el conductor</span>
            </div>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <div>Total:</div>
              <span className="fw-bold">
                S/ {(calculateTotal()).toFixed(2)}
              </span>
            </div>
            <div className="d-grid mt-4">
              <button
                className="btn btn-primary btn-lg d-flex justify-content-between align-items-center"
                onClick={goToPay}
                disabled={cart.length === 0}
              >
                Ir a la caja{" "}
                <span className="fw-bold">
                  S/ {(calculateTotal()).toFixed(2)}
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
