// src/state/cartState.js
import { atom } from "recoil";

// --- Utilidades para localStorage ---
const loadCartFromStorage = () => {
  try {
    const storedCart = localStorage.getItem("cart");
    return storedCart ? JSON.parse(storedCart) : [];
  } catch (e) {
    console.error("Error al cargar el carrito desde localStorage", e);
    return [];
  }
};

const saveCartToStorage = (cart) => {
  try {
    localStorage.setItem("cart", JSON.stringify(cart));
  } catch (e) {
    console.error("Error al guardar el carrito en localStorage", e);
  }
};

// --- Estado global con efecto de persistencia ---
export const cartState = atom({
  key: "cartState",
  default: loadCartFromStorage(),
  effects_UNSTABLE: [
    ({ onSet }) => {
      onSet((newCart) => {
        saveCartToStorage(newCart);
      });
    },
  ],
});

// --- Función para agregar un producto al carrito ---
export const addToCart = (cart, product) => {
  const existingProductIndex = cart.findIndex((item) => item.id === product.id);

  if (existingProductIndex !== -1) {
    const updatedCart = [...cart];
    updatedCart[existingProductIndex] = {
      ...updatedCart[existingProductIndex],
      quantity: updatedCart[existingProductIndex].quantity + product.quantity,
    };
    return updatedCart;
  }

  return [...cart, product];
};

// --- Función para eliminar un producto del carrito ---
export const removeFromCart = (cart, productId) => {
  return cart.filter((item) => item.id !== productId);
};

// --- Función para actualizar cantidad de producto (+1 o -1) ---
export const updateQuantity = (cart, productId, change) => {
  return cart.map((item) => {
    if (item.id === productId) {
      const newQuantity = Math.max(1, item.quantity + change); // No menos de 1
      return { ...item, quantity: newQuantity };
    }
    return item;
  });
};
