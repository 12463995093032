import { axiosPublic } from "./services.config";

export const getOneBusiness = async ({
  businessId,
  includeProducts = false,
}) => {
  try {
    const url = `business/${businessId}?products=${includeProducts}`;
    const { data } = await axiosPublic.get(url);
    return await JSON.parse(data);
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const getBusinesses = async () => {
  try {
    const url = `business/`;
    const { data } = await axiosPublic.get(url);
    return await JSON.parse(data);
  } catch (error) {
    console.error("Error:", error);
  }
}
