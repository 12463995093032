import React, { useEffect, useState } from 'react';
import Lottie from 'lottie-react';
import locationErrorAnimation from '../animation/location-not-found.json';

const allowedLocations = [
  { lat: -6.865, lon: -78.826 }  // Celendín
];

const isLocationAllowed = (userLat, userLon) => {
  const maxDistance = 0.3;
  return allowedLocations.some(({ lat, lon }) => {
    const distance = Math.sqrt((lat - userLat) ** 2 + (lon - userLon) ** 2);
    return distance < maxDistance;
  });
};

const LocationGuard = ({ children }) => {
  const [locationAllowed, setLocationAllowed] = useState(null);

  useEffect(() => {
    const override = localStorage.getItem('locationOverride');
    if (override === 'true') {
      setLocationAllowed(true);
      return;
    }

    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        const allowed = isLocationAllowed(latitude, longitude);
        setLocationAllowed(allowed);
      },
      (error) => {
        console.error('Error getting location:', error);
        setLocationAllowed(false);
      }
    );
  }, []);

  const handleOverride = () => {
    localStorage.setItem('locationOverride', 'true');
    setLocationAllowed(true);
  };

  if (locationAllowed === null) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <div className="text-center">
          <div className="spinner-border text-primary mb-3" role="status"></div>
          <p className="text-muted">Detectando ubicación...</p>
        </div>
      </div>
    );
  }

  if (!locationAllowed) {
    return (
      <div className="container d-flex flex-column justify-content-center align-items-center vh-100 text-center">
        <div className="card shadow p-4">
          <Lottie animationData={locationErrorAnimation} style={{ height: 200 }} />
          <h1 className="text-danger mb-3">Servicio no disponible en tu ubicación</h1>
          <p className="text-muted">
            Actualmente solo ofrecemos cobertura en <strong>Celendín</strong>.
          </p>
          <button className="btn btn-primary mt-3" onClick={handleOverride}>
            Pedir para alguien en Celendín
          </button>
        </div>
      </div>
    );
  }

  return <>{children}</>;
};

export default LocationGuard;
