import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MagnifyingGlass } from "react-loader-spinner";
import ScrollToTop from "../ScrollToTop";
import { useRecoilState } from "recoil"; // Usamos Recoil para manejar el carrito
import { cartState } from "./cartState"; // Estado atómico de Recoil para el carrito

const ShopCart = () => {
  const [cart, setCart] = useRecoilState(cartState); // Usamos Recoil para acceder y actualizar el carrito
  const [total, setTotal] = useState(0);
  const [loaderStatus, setLoaderStatus] = useState(true);
  const [categoryId, setCategoryId] = useState(null); // Para almacenar el ID de la categoría actual

  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      setLoaderStatus(false); // Control de estado de carga
    }, 1500);

    if (cart && Array.isArray(cart)) {
      calculateTotal(cart); // Calcular total cuando el carrito cambia
    }
  }, [cart]); // El cálculo solo se hará cuando cart cambie

  useEffect(() => {
    const storedCart = localStorage.getItem("cart");
    if (storedCart) {
      setCart(JSON.parse(storedCart)); // Restaurar carrito desde localStorage
    }

    const currentCategoryId = localStorage.getItem("currentCategoryId");
    setCategoryId(currentCategoryId); // Almacenar el ID de la categoría actual
  }, [setCart]);

  const calculateTotal = (cart) => {
    const totalPrice = cart.reduce((acc, product) => {
      const price = product.discountPrice || product.price; // Si hay descuento, usamos el precio con descuento
      return acc + price * product.quantity;
    }, 0);
    setTotal(totalPrice); // Actualiza el total
  };

  const handleCheckout = () => {
    navigate("/ShopCheckOut");
  };

  const handleRemoveItem = (id) => {
    setCart(cart.filter((item) => item.id !== id)); // Filtramos el producto para eliminarlo del carrito
  };

  const handleUpdateQuantity = (id, quantity) => {
    if (quantity < 1) return; // No permitir cantidades menores a 1
    if (quantity > 10) return; // No permitir cantidades mayores a 10
    setCart(
      cart.map((item) =>
        item.id === id ? { ...item, quantity: quantity } : item
      )
    ); // Actualizamos la cantidad del producto en el carrito
  };

  return (
    <div>
      {loaderStatus ? (
        <div className="loader-container">
          <MagnifyingGlass
            visible={true}
            height="100"
            width="100"
            ariaLabel="magnifying-glass-loading"
            glassColor="#c0efff"
            color="#1A80E5"
          />
        </div>
      ) : (
        <>
          <ScrollToTop />
          <div>
            <section className="mb-lg-14 mb-8 mt-8">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="card py-1 border-0 mb-8">
                      <div>
                        <h1 className="fw-bold">Carrito de Compras</h1>
                        {cart.length === 0 ? (
                          <p
                            className="list-group-item py-3 py-lg-0 px-0"
                            style={{ fontSize: "20px", marginTop: "20px" }}
                          >
                            Tu carrito está vacío. ¡Comienza a agregar productos!
                          </p>
                        ) : (
                          <p className="mb-0">Compras en curso</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-8 col-md-7">
                    <div className="py-3">
                      <ul className="list-group list-group-flush">
                        {cart.length > 0
                          ? cart.map((product) => (
                              <li
                                className="list-group-item py-3 py-lg-0 px-0"
                                key={product.id}
                                style={{ marginBottom: "15px" }}
                              >
                                <div className="row align-items-center">
                                  <div className="col-3 col-md-2">
                                    <img
                                      src={product.imageUrl}
                                      alt={product.name}
                                      className="img-fluid"
                                    />
                                  </div>
                                  <div className="col-4 col-md-6">
                                    <h6 className="mb-0">{product.name}</h6>
                                    <span>
                                      {product.discountPrice ? (
                                        <>
                                          <small
                                            className="text-muted"
                                            style={{
                                              textDecoration: "line-through",
                                            }}
                                          >
                                            S/ {product.price}
                                          </small>
                                          <span className="text-danger">
                                            {" "}
                                            S/ {product.discountPrice}
                                          </span>
                                        </>
                                      ) : (
                                        <small className="text-muted">
                                          S/ {product.price}
                                        </small>
                                      )}
                                      <p
                                        className="text-muted"
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "14px",
                                          marginTop: "5px",
                                        }}
                                        onClick={() => handleRemoveItem(product.id)}
                                      >
                                        Eliminar
                                      </p>
                                    </span>
                                  </div>
                                  <div className="col-3 col-md-3 col-lg-2">
                                    <div className="input-group flex-nowrap justify-content-center">
                                      <input
                                        type="button"
                                        value="-"
                                        className="button-minus form-control text-center"
                                        onClick={() =>
                                          handleUpdateQuantity(
                                            product.id,
                                            product.quantity - 1
                                          )
                                        }
                                      />
                                      <input
                                        type="number"
                                        step={1}
                                        min={1}
                                        max={10}
                                        value={product.quantity ?? 1}
                                        readOnly
                                        className="quantity-field form-control text-center"
                                        style={{
                                          minWidth: "50px",
                                          fontSize: "1rem",
                                          color: "#000",
                                        }}
                                      />
                                      <input
                                        type="button"
                                        value="+"
                                        className="button-plus form-control text-center"
                                        onClick={() =>
                                          handleUpdateQuantity(
                                            product.id,
                                            product.quantity + 1
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-2 text-lg-end text-start text-md-end col-md-2">
                                    <span className="fw-bold">
                                      S/.{" "}
                                      {product.discountPrice
                                        ? product.discountPrice *
                                          product.quantity
                                        : product.price * product.quantity}
                                    </span>
                                  </div>
                                </div>
                              </li>
                            ))
                          : null}
                      </ul>
                      <div className="d-flex justify-content-between mt-4">
                        <Link
                          to={`/category/${categoryId}`}
                          className="btn btn-primary"
                        >
                          Seguir comprando
                        </Link>
                      </div>
                    </div>
                  </div>
                  {cart.length > 0 && (
                    <div className="col-12 col-lg-4 col-md-5">
                      <div className="mb-5 card mt-6">
                        <div className="card-body p-6">
                          <h2 className="h5 mb-4">Resumen</h2>
                          <div className="card mb-2">
                            <ul className="list-group list-group-flush">
                              <li className="list-group-item d-flex justify-content-between align-items-start">
                                <div className="me-auto">
                                  <div>Subtotal de los productos</div>
                                </div>
                                <span>
                                  S/.{" "}
                                  {cart.reduce(
                                    (acc, product) =>
                                      acc +
                                      (product.discountPrice || product.price) *
                                        product.quantity,
                                    0
                                  )}
                                </span>
                              </li>
                              <li className="list-group-item d-flex justify-content-between align-items-start">
                                <div className="me-auto">
                                  <div>Costo de envío</div>
                                </div>
                                <span>A coordinar con el conductor</span>
                              </li>
                              <li className="list-group-item d-flex justify-content-between align-items-start">
                                <div className="me-auto">
                                  <div className="fw-bold">Total</div>
                                </div>
                                <span className="fw-bold">S/. {total}</span>
                              </li>
                            </ul>
                          </div>
                          <div className="d-grid mb-1 mt-4">
                            <button
                              className="btn btn-primary btn-lg d-flex justify-content-between align-items-center"
                              type="button"
                              onClick={handleCheckout}
                              disabled={cart.length === 0}
                            >
                              Ir a la caja{" "}
                              <span className="fw-bold">
                                S/. {total}
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </section>
          </div>
        </>
      )}
    </div>
  );
};

export default ShopCart;
