import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./Component/Header";
import Footer from "./Component/Footer";
import AutoScrollTop from "./pages/AutoScrollTop";
import LocationGuard from './Component/LocationGuard';
import TermsModal from "./Component/TermsModal";
// Pages
import Home from "./pages/Home";
import GuideShop from "./pages/GuideShop/GuideShop";
import Error404 from "./pages/Error/Error404";
// About pages
import AboutUs from "./pages/About/AboutUs";
import Blog from "./pages/About/Blog";
import BlogCategory from "./pages/About/BlogCategory";
import Contact from "./pages/About/Contact";
// Shop pages
import Shop from "./pages/Shop/Shop";
import ShopGridCol3 from "./pages/Shop/ShopGridCol3";
import ShopListCol from "./pages/Shop/ShopListCol";
import ShopCart from "./pages/Shop/ShopCart";
import ShopCheckOut from "./pages/Shop/ShopCheckOut";
import ShopWishList from "./pages/Shop/ShopWishList";
// Store pages
import StoreList from "./pages/store/StoreList";
import SingleShop from "./pages/store/SingleShop";
// Account pages
import MyAccountOrder from "./pages/Accounts/MyAccountOrder";
import MyAccountSetting from "./pages/Accounts/MyAcconutSetting";
import MyAcconutNotification from "./pages/Accounts/MyAcconutNotification";
import MyAcconutPaymentMethod from "./pages/Accounts/MyAcconutPaymentMethod";
import MyAccountAddress from "./pages/Accounts/MyAccountAddress";
import MyAccountForgetPassword from "./pages/Accounts/MyAccountForgetPassword";
import MyAccountSignIn from "./pages/Accounts/MyAccountSignIn";
import MyAccountSignUp from "./pages/Accounts/MyAccountSignUp";
// react-query
import { QueryClient, QueryClientProvider } from "react-query";
// Recoil
import { RecoilRoot } from "recoil"; // Importa RecoilRoot

const App = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: 2,
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      {/* Wrap the app with RecoilRoot */}
      <RecoilRoot>
        <Router>
          <AutoScrollTop />
          <Header />
          <LocationGuard>
          <TermsModal />
          <Routes>
            <Route path="/" element={<Home />} />
            {/* Shop pages */}
            <Route path="/category/:id" element={<Shop />} />
            <Route path="/ShopGridCol3" element={<ShopGridCol3 />} />
            <Route path="/ShopListCol" element={<ShopListCol />} />
            <Route path="/ShopWishList" element={<ShopWishList />} />
            <Route path="/ShopCheckOut" element={<ShopCheckOut />} />
            <Route path="/ShopCart" element={<ShopCart />} />
            {/* Store pages */}
            <Route path="/StoreList" element={<StoreList />} />
            <Route path="/SingleShop" element={<SingleShop />} />
            {/* Accounts pages */}
            <Route path="/MyAccountOrder" element={<MyAccountOrder />} />
            <Route path="/MyAccountSetting" element={<MyAccountSetting />} />
            <Route path="/MyAcconutNotification" element={<MyAcconutNotification />} />
            <Route path="/MyAcconutPaymentMethod" element={<MyAcconutPaymentMethod />} />
            <Route path="/MyAccountAddress" element={<MyAccountAddress />} />
            <Route path="/MyAccountForgetPassword" element={<MyAccountForgetPassword />} />
            <Route path="/MyAccountSignIn" element={<MyAccountSignIn />} />
            <Route path="/MyAccountSignUp" element={<MyAccountSignUp />} />
            {/* About pages */}
            <Route path="/stores/:id" element={<Blog />} />
            <Route path="/BlogCategory" element={<BlogCategory />} />
            <Route path="/Contact" element={<Contact />} />
            <Route path="/AboutUs" element={<AboutUs />} />
            {/* Guide Pages */}
            <Route path="/GuideShop" element={<GuideShop />} />
            {/* Error pages */}
            <Route path="*" element={<Error404 />} />
          </Routes>
          </LocationGuard>
          <Footer />
        </Router>
      </RecoilRoot>
    </QueryClientProvider>
  );
};

export default App;
