import { Zoom } from "react-awesome-reveal";

import refresh from "../img/refresh-cw.svg";
import gift from "../img/gift.svg";
import clock from "../img/clock.svg";
import store from "../img/package.svg";

export const AltokeSection = () => {
  return (
    <>
      <div className="col-md-12 mb-6">
        <div className="section-head text-center mt-8">
          <h3 className="h3style" data-title="Daily Best Sells">
            ¿Por qué Altoke?
          </h3>
          <div className="wt-separator bg-primarys"></div>
          <div className="wt-separator2 bg-primarys"></div>
        </div>
      </div>
      <section className="my-lg-14 my-8">
        <div className="container" style={{ marginTop: 50 }}>
          <div
            className="row justify-content-center  g-4"
            style={{ textAlign: "center" }}
          >
            <div className="col-md-3 col-sm-6 fade-zoom ">
              <Zoom>
                <div className="shadow-effect">
                  <div className="wt-icon-box-wraper center p-a25 p-b50 m-b30 bdr-1 bdr-gray bdr-solid corner-radius step-icon-box bg-white v-icon-effect">
                    <div className="icon-lg m-b20">
                      <div className="mb-6">
                        <img src={refresh} alt="refresh" />
                      </div>
                    </div>
                    <div className="icon-content">
                      <h3 className="h5 mb-3">Envios rapidos y seguros</h3>
                      <p>
                        Recibe tu pedido en minutos. Nuestros repartidores están
                        listos para llevar tu comida o productos de manera
                        rápida y segura, directamente a tu puerta.
                      </p>
                    </div>
                  </div>
                </div>
              </Zoom>
            </div>
            <div className="col-md-3 col-sm-12 fade-zoom">
              <Zoom>
                <div className="shadow-effect">
                  <div className="wt-icon-box-wraper center p-a25 p-b50 m-b30 bdr-1 bdr-gray bdr-solid corner-radius step-icon-box bg-white v-icon-effect">
                    <div className="icon-lg m-b20">
                      <div className="mb-6">
                        <img src={store} alt="package" />
                      </div>
                    </div>
                    <div className="icon-content">
                      <h3 className="h5 mb-3">
                        Variedad de restaurantes y tiendas
                      </h3>
                      <p>
                        Elige entre una gran selección de restaurantes y tiendas
                        locales. Desde comida rápida hasta productos esenciales,
                        todo en un solo lugar.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </Zoom>
            </div>
            <div className="col-md-3 col-sm-12 fade-zoom">
              <Zoom>
                <div className="shadow-effect">
                  <div className="wt-icon-box-wraper center p-a25 p-b50 m-b30 bdr-1 bdr-gray bdr-solid corner-radius step-icon-box bg-white v-icon-effect">
                    <div className="icon-lg m-b20">
                      <div className="mb-6">
                        <img src={gift} alt="gift" />
                      </div>
                    </div>
                    <div className="icon-content">
                      <h3 className="h5 mb-3">
                        ¡Precios justos y ofertas exclusivas!
                      </h3>
                      <p>
                        Disfruta de los mejores precios y promociones en tus
                        restaurantes favoritos. Además, accede a descuentos
                        especiales en tiendas afiliadas.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </Zoom>
            </div>
            <div className="col-md-3 col-sm-12 fade-zoom">
              <Zoom>
                <div className="shadow-effect">
                  <div className="wt-icon-box-wraper center p-a25 p-b50 m-b30 bdr-1 bdr-gray bdr-solid corner-radius step-icon-box bg-white v-icon-effect">
                    <div className="icon-lg m-b20">
                      <div className="mb-6">
                        <img src={clock} alt="clock" />
                      </div>
                    </div>
                    <div className="icon-content">
                      <h3 className="h5 mb-3">Pide fácil por WhatsApp</h3>
                      <p>
                        Haz tu pedido directamente desde la web y recíbelo sin
                        complicaciones. Altoke se encarga de gestionar todo con
                        los negocios y repartidores.
                      </p>
                    </div>
                  </div>
                </div>
              </Zoom>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
