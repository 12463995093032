import { FoodCard } from "../pages/Shop/FoodCard";
import { ProductCard } from "../pages/Shop/ProductCard";

export const renderProductCard = ({ product, categoryKey, handleAddToCart, canBuy }) => {
  if (categoryKey === "FOOD")
    return <FoodCard product={product} addCarFunction={handleAddToCart} canBuy={canBuy} />;

  else
    return <ProductCard product={product} addCarFunction={handleAddToCart} canBuy={canBuy} />;

};
