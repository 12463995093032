import React from "react";
import { Slide } from "react-awesome-reveal";
import { Link } from "react-router-dom";

function AdCard({ imageUrl, title, description, slideTo }) {
  const randomNumber = Math.floor(Math.random() * 2);

  const direction = randomNumber === 1 ? "left" : "right";

  return (
    <Slide direction={slideTo || direction}>
      <div className="ratio ratio-16x9">
        <div
          className="py-3 py-md-5 px-8 rounded-3 d-flex align-items-center"
          style={{
            background: `url(${imageUrl}) no-repeat`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="w-50" style={{ color: "#000" }}>
            <h3
              className="fw-bold mb-3 fs-4"
              style={{
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                WebkitLineClamp: 2,
              }}
            >
              {title}
            </h3>
            <p
              className="mb-4"
              style={{
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                WebkitLineClamp: 2,
              }}
            >
              {description}
            </p>
            <Link to={{}} className="btn btn-dark">
              Pide Altoke
            </Link>
          </div>
        </div>
      </div>
    </Slide>
  );
}

export default AdCard;
