import React from "react";
import { Slide, Zoom } from "react-awesome-reveal";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
// import SwiperCore, { Pagination } from 'swiper/core';
// import 'swiper/swiper.min.css';
// import 'swiper/components/pagination/pagination.min.css';

const SocialNetworksCarousel = () => {
  return (
    <section className="container text-center mt-8 py-5 my-2 my-md-4 my-lg-5">
      <Slide direction="up">
        <h2 className="h1 mb-4">Nuestras Redes Sociales</h2>
        <p className="fs-lg text-muted pb-2 mb-5">
          ¡Siguenos en nuestras redes sociales y no te pierdas de lo ultimo en
          ofertas!
        </p>
      </Slide>
      <Swiper
        slidesPerView={4}
        pagination={{ clickable: true, el: ".swiper-pagination" }}
        breakpoints={{
          500: { slidesPerView: 3 },
          650: { slidesPerView: 4 },
          900: { slidesPerView: 5 },
          1100: { slidesPerView: 6 },
        }}
      >
        <SwiperSlide>
          <div className="position-relative text-center border-end mx-n1">
            {" "}
            <Zoom>
              <Link
                href="https://www.facebook.com/people/Altoke-Driver/61571824696003/"
                target="_blank"
                className="btn btn-icon btn-secondary btn-facebook btn-lg stretched-link"
              >
                <i className="bx bxl-facebook"></i>
              </Link>{" "}
            </Zoom>
            <div className="pt-4">
              <h6 className="mb-1">Facebook</h6>
              {/* <p className="fs-sm text-muted mb-0">silicon</p> */}
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="position-relative text-center border-end mx-n1">
            <Zoom>
              <a
                href="https://www.instagram.com/altoke.driver/"
                className="btn btn-icon btn-secondary btn-instagram btn-lg stretched-link"
                target="_blank"
              >
                <i className="bx bxl-instagram"></i>
              </a>
            </Zoom>
            <div className="pt-4">
              <h6 className="mb-1">Instagram</h6>
              {/* <p className="fs-sm text-muted mb-0">@silicon</p> */}
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="position-relative text-center border-end mx-n1">
            {" "}
            <Zoom>
              <a
                href="https://www.tiktok.com/@altokedriver"
                className="btn btn-icon btn-secondary btn-twitter btn-lg stretched-link"
                target="_blank"
              >
                <i className="bx bxl-tiktok"></i>
              </a>
            </Zoom>
            <div className="pt-4">
              <h6 className="mb-1">Tik Tok </h6>
              {/* <p className="fs-sm text-muted mb-0">@silicon</p> */}
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="position-relative text-center border-end mx-n1">
            <Zoom>
              <a
                href="https://www.youtube.com/@AltokeDriver"
                className="btn btn-icon btn-secondary btn-youtube btn-lg stretched-link"
                target="_blank"
              >
                <i className="bx bxl-youtube"></i>
              </a>
            </Zoom>
            <div className="pt-4">
              <h6 className="mb-1">YouTube</h6>
              {/* <p className="fs-sm text-muted mb-0">Silicon</p> */}
            </div>
          </div>
        </SwiperSlide>
      </Swiper>

      <div className="swiper-pagination position-relative bottom-0 pt-3 mt-4"></div>
    </section>
  );
};

export default SocialNetworksCarousel;
