import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { MagnifyingGlass } from "react-loader-spinner";
import ScrollToTop from "../ScrollToTop";
import { ProductCard } from "./ProductCard";
import { FoodCard } from "./FoodCard";
import { useRecoilState } from "recoil";
import { cartState } from "./cartState";
import { useGetGategory } from "../../hooks/categories-hooks";
import { FaSearch } from "react-icons/fa";

function Dropdown() {
  const [cart, setCart] = useRecoilState(cartState);
  const [loaderStatus, setLoaderStatus] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState(""); // 🔍 Búsqueda
  const productsPerPage = 12;
  const navigate = useNavigate();

  const { id } = useParams();
  const { data: category } = useGetGategory(Number(id), true);

  useEffect(() => {
    setTimeout(() => {
      setLoaderStatus(false);
    }, 1500);
  }, []);

  const handleAddToCart = (product) => {
    const existingProduct = cart.find((item) => item.id === product.id);
    let updatedCart;

    if (existingProduct) {
      updatedCart = cart.map((item) =>
        item.id === product.id
          ? { ...item, quantity: item.quantity + 1 }
          : item
      );
    } else {
      updatedCart = [...cart, { ...product, quantity: 1 }];
    }

    setCart(updatedCart);
    localStorage.setItem("cart", JSON.stringify(updatedCart));
    navigate("/ShopCart");
  };

  useEffect(() => {
    if (category) {
      localStorage.setItem("currentCategoryId", category.id);
    }
  }, [category]);

  const renderProductCard = (product, categoryKey) => {
    return categoryKey === "FOOD" ? (
      <FoodCard key={product.id} product={product} addCarFunction={handleAddToCart} />
    ) : (
      <ProductCard key={product.id} product={product} addCarFunction={handleAddToCart} />
    );
  };

  // 🔍 Aplicar filtro antes de paginar
  const filteredProducts = category?.products?.filter((product) => {
    const query = searchQuery.toLowerCase();
    const nameMatch = product.name?.toLowerCase().includes(query);
    const descriptionMatch = product.description?.toLowerCase().includes(query);
    return nameMatch || descriptionMatch;
  }) || [];

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const paginatedProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);
  const totalPages = Math.ceil(filteredProducts.length / productsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    setCurrentPage(1); // Reiniciar a la primera página
  };

  return (
    <div>
      {loaderStatus ? (
        <div className="loader-container">
          <MagnifyingGlass
            visible={true}
            height="100"
            width="100"
            ariaLabel="magnifying-glass-loading"
            wrapperClassName="magnifying-glass-wrapper"
            glassColor="#c0efff"
            color="#1A80E5"
          />
        </div>
      ) : (
        <>
          <ScrollToTop />
          <div className="container">
            <div className="row">
              <h5 className="mb-3 mt-8">Categorías</h5>

              {/* 🔍 Búsqueda desktop */}
              <form className="d-none d-lg-flex w-50 mb-4" onSubmit={handleSearchSubmit}>
                <div className="input-group">
                  <input
                    className="form-control"
                    type="search"
                    placeholder="Escribe tu búsqueda..."
                    aria-label="Search"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                  <button className="btn btn-outline-primary" type="submit">
                    <FaSearch />
                  </button>
                </div>
              </form>

              <div className="w-100 col-lg-9 col-md-8">
                <div className="card mb-4 bg-light border-0">
                  <div className="card-body p-9">
                    <h1 className="mb-0">{category ? category.name : "Cargando..."}</h1>
                  </div>
                </div>

                {/* Resultado de búsqueda */}
                <div className="d-md-flex justify-content-between align-items-center">
                  <p className="mb-3 mb-md-0">
                    <span className="text-dark">{filteredProducts.length}</span> Productos encontrados
                  </p>
                </div>

                <div className="row g-4 row-cols-xl-4 row-cols-lg-3 row-cols-2 row-cols-md-2 mt-2">
                  {paginatedProducts.length > 0 ? (
                    paginatedProducts.map((product) =>
                      renderProductCard(product, category.categoryKey)
                    )
                  ) : (
                    <p>No se encontraron productos.</p>
                  )}
                </div>

                {/* Paginación */}
                <div className="row mt-8">
                  <div className="col">
                    <nav>
                      <ul className="pagination">
                        <li className="page-item">
                          <Link
                            className="page-link mx-1 rounded-3"
                            to="#"
                            aria-label="Previous"
                            onClick={() => currentPage > 1 && paginate(currentPage - 1)}
                          >
                            <i className="fa fa-chevron-left" />
                          </Link>
                        </li>
                        {[...Array(totalPages)].map((_, pageIndex) => (
                          <li key={pageIndex} className="page-item">
                            <Link
                              className={`page-link mx-1 rounded-3 ${
                                currentPage === pageIndex + 1 ? "active" : ""
                              }`}
                              to="#"
                              onClick={() => paginate(pageIndex + 1)}
                            >
                              {pageIndex + 1}
                            </Link>
                          </li>
                        ))}
                        <li className="page-item">
                          <Link
                            className="page-link mx-1 rounded-3 text-body"
                            to="#"
                            aria-label="Next"
                            onClick={() => currentPage < totalPages && paginate(currentPage + 1)}
                          >
                            <i className="fa fa-chevron-right" />
                          </Link>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Dropdown;
