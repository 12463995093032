import AdCard from "./AdCard";
import grocerybanner from "../images/grocery-banner.png";
import grocerybanner2 from "../images/grocery-banner-2.jpg";
import adbanner2 from "../images/ad-banner-2.jpg";

export const AdSection = () => {
  return (
    <>
      <div className="section-head text-center mt-8">
        <h3 className="h3style" data-title="Daily Best Sells">
          Nuestras promociones
        </h3>
        <div className="wt-separator bg-primarys"></div>
        <div className="wt-separator2 bg-primarys"></div>
      </div>
      <section className="container my-3">
        <div className="row">
          {/* Card 1 */}
          <div className="col-12 col-md-6 col-lg-4 mb-4">
            <AdCard
              title="Hidrátate con la mejor agua"
              imageUrl={adbanner2}
              description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
            />
          </div>

          {/* Card 2 */}
          <div className="col-12 col-md-6 col-lg-4 mb-4">
            <AdCard
              title="Hidrátate con la mejor agua"
              imageUrl={adbanner2}
              description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
            />
          </div>

          {/* Card 3 */}
          <div className="col-12 col-md-6 col-lg-4 mb-4">
            <AdCard
              title="Hidrátate con la mejor agua"
              imageUrl={adbanner2}
              description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
            />
          </div>
        </div>
      </section>
      <section>
        <div className="container ">
          <div className="row">
            <div className="col-12 col-lg-6 mb-3 mb-lg-0  fade-in-left">
              <AdCard
                slideTo={"right"}
                title="Hamburguesas 4x3"
                description="Pide por Altoke y disfruta esta promo en casa"
                imageUrl={grocerybanner}
              />
            </div>
            <div className="col-12 col-lg-6 fade-in-left ">
              <AdCard
                slideTo={"left"}
                title="Hidratate con la mejor calidad de agua"
                description="Pide por Altoke con delivery gratis"
                imageUrl={grocerybanner2}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
