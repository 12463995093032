import { Zoom } from "react-awesome-reveal";
import { Link } from "react-router-dom";

export const CategoryCircle = ({ imageUrl, name, id }) => {
  return (
    <div className="col-lg-2 col-md-4 col-6 fade-zoom" key={id}>
      <Zoom>
        <div className="text-center mb-10">
          <Link to={`/category/${id}`}>
            <img
              src={imageUrl || ""}
              alt={name}
              className="card-image rounded-circle"
            />
          </Link>
          <div className="mt-4">
            <h5 className="fs-6 mb-0">
              <Link
                to={`/category/${id}`}
                className="text-inherit text-decoration-none"
              >
                {name}
              </Link>
            </h5>
          </div>
        </div>
      </Zoom>
    </div>
  );
};
