import { Link } from "react-router-dom";
import { ProductDetail } from "./ProductDetail";
import { useState } from "react";

export const ProductCard = ({ product, canBuy = true }) => {
  const [show, setShow] = useState(false);

  const showDetails = () => {
    setShow(true);
  };

  const hideDetails = () => {
    setShow(false);
  };

  return (
    <>
      <ProductDetail show={show} product={product} handleClose={hideDetails} />
      <div className="col" key={product.id}>
        <div className="card card-product h-100">
          <div className="card-body">
            <div className="text-center position-relative">
              <div onClick={() => showDetails()}>
                <div className="img-zoom">
                  <img
                    src={product.imageUrl || ""}
                    alt={product.name}
                    className="card-img-top  rounded-3 w-100"
                    style={{ objectFit: "contain", aspectRatio: 4 / 3 }}
                  />
                </div>
              </div>
            </div>
            <h2 className="fs-6 text-truncate">
              <Link to="#!" className="text-inherit text-decoration-none ">
                {product.name}
              </Link>
            </h2>
            <div className="d-flex justify-content-between align-items-center mt-3">
              <div>
                <span
                  className={
                    product?.discountPrice
                      ? "text-dark text-decoration-line-through"
                      : "text-dark"
                  }
                >{`S/.${product.price.toFixed(2)}`}</span>
                {product.discountPrice && (
                  <span
                    className={
                      product?.discountPrice
                        ? "mx-2 text-danger fw-bold fs-5"
                        : "d-none"
                    }
                  >{`S/.${product.discountPrice.toFixed(2)}`}</span>
                )}
              </div>
              <div className="d-none d-lg-block">
                {!canBuy ? (
                  <button className="btn btn-light rounded w-100 fs-6 fw-bold">
                    cerrado
                  </button>
                ) : (
                  <button
                    onClick={() => showDetails()}
                    className="btn btn-primary btn-sm"
                  >
                    <i className="fa fa-cart-plus" />
                  </button>
                )}
              </div>
            </div>
            <div className="d-lg-none d-block my-2 mx-auto">
              {!canBuy ? (
                <button className="btn btn-light rounded w-100 fs-6 fw-bold">
                  cerrado
                </button>
              ) : (
                <button
                  onClick={() => showDetails()}
                  className="btn btn-primary btn-sm"
                >
                  <i className="fa fa-cart-plus" />
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
