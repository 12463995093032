import { axiosPublic } from "./services.config";

export const getCategories = async () => {
  try {
    const { data } = await axiosPublic.get("categories");
    return await JSON.parse(data);
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const getCategoryProducts = async (id, products = true) => {
  try {
    const url = `categories/${id}?products=${products}`;
    const { data } = await axiosPublic.get(url);
    return await JSON.parse(data);
  } catch (error) {
    throw error;
  }
};
