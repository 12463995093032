import { Zoom } from "react-awesome-reveal";
import { Link } from "react-router-dom";
import { ProductDetail } from "./ProductDetail";
import { useState } from "react";

export const FoodCard = ({ product, canBuy = true }) => {
  const [isDetails, setIsDetails] = useState(false);

  const showDetails = () => {
    setIsDetails(true);
  };

  const hideDetails = () => {
    setIsDetails(false);
  };

  return (
    <>
      <ProductDetail
        show={isDetails}
        product={product}
        handleClose={hideDetails}
      />
      <div className="col-12 col-md-6 col-lg-4 mb-4">
        <Zoom>
          <div className="card h-100">
            <div className="img-zoom" onClick={showDetails}>
              <img
                src={product.imageUrl || ""}
                alt={product.name || "Foto del producto"}
                className="card-img-top rounded-3 w-100"
                style={{
                  objectFit: "cover",
                  width: "100%",
                  aspectRatio: 16 / 9,
                }}
              />
            </div>

            {/* text */}
            <div className="card-body d-flex flex-column">
              <h2 className="h5">
                <p className="text-inherit text-truncate">
                  {product && product.name
                    ? product.name
                    : "Producto sin nombre"}
                </p>
              </h2>
              {/* Limitando las líneas de la descripción - Método Bootstrap */}
              <p className="text-truncate" style={{ maxHeight: "3.6em" }}>
                {product && product.description
                  ? product.description
                  : "Producto sin descripción"}
              </p>
              <div className="mt-auto">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <span
                      className={
                        product?.discountPrice
                          ? "text-dark text-decoration-line-through"
                          : "text-dark"
                      }
                    >{`S/.${
                      product && product.price ? product.price.toFixed(2) : 0
                    }`}</span>
                    {product.discountPrice && (
                      <span className="text-danger fw-bold fs-5 ms-2">
                        {`S/.${
                          product && product.discountPrice
                            ? product.discountPrice.toFixed(2)
                            : 0
                        }`}
                      </span>
                    )}
                  </div>
                  <div>
                    {!canBuy ? (
                      <button className="btn btn-light rounded w-100 fs-6 fw-bold">
                        cerrado
                      </button>
                    ) : (
                      <button
                        onClick={() => showDetails()}
                        className="btn btn-primary btn-sm"
                      >
                        <i className="fa fa-cart-plus" />
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Zoom>
      </div>
    </>
  );
};
