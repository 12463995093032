import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Slide, Zoom } from "react-awesome-reveal";
import { MagnifyingGlass } from "react-loader-spinner";
import ScrollToTop from "../ScrollToTop";
import graphics from "../../images/store-graphics.svg";
import { useGetBusinesses } from "../../hooks/business-hooks";

const BusinessCard = ({ store, index }) => {
  return (
    <Zoom key={index}>
      <div className="col ratio ratio-16x9">
        <div
          className="card flex-row align-items-center p-8 card-product transition"
          style={{ transition: "box-shadow 0.3s" }}
          onMouseEnter={(e) =>
            (e.currentTarget.style.boxShadow = "0 10px 15px rgba(0, 0, 0, 0.2)")
          }
          onMouseLeave={(e) => (e.currentTarget.style.boxShadow = "none")}
        >
          {/* Badge de "Cerrado" */}
          {!store.isOpen && (
            <div
              className="badge bg-danger text-white fs-6 position-absolute"
              style={{
                top: "0px",
                right: "0px",
                padding: "5px 10px",
                fontSize: "0.8rem",
                borderRadius: "0px 0px 0px 8px",
                zIndex: 10,
              }}
            >
              Cerrado
            </div>
          )}
          <div>
            <Link to={`/stores/${store.id}`}>
              <img
                src={store.logoUrl}
                alt={store.name}
                className="rounded-circle icon-shape icon-xl"
              />
            </Link>
          </div>
          <div className="ms-6">
            <h5 className="mb-1">
              <Link
                to={`/stores/${store.id}`}
                className="text-inherit text-decoration-none text-truncate"
                style={{
                  display: "-webkit-box",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                  maxWidth: "100%",
                  wordWrap: "break-word",
                  whiteSpace: "normal",
                }}
                onMouseEnter={(e) => (e.target.style.color = "#007BFF")}
                onMouseLeave={(e) => (e.target.style.color = "inherit")}
              >
                {store.name}
              </Link>
            </h5>
            <div
              className="small text-muted text-truncate text-justify"
              style={{
                display: "-webkit-box",
                WebkitLineClamp: 3,
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                maxWidth: "100%",
                wordWrap: "break-word",
                whiteSpace: "normal",
              }}
            >
              {store.description && <span>{store.description}</span>}
            </div>
            <div className="mt-1">
              <strong>{store.address}</strong>
            </div>
          </div>
        </div>
      </div>
    </Zoom>
  );
};

const StoreList = () => {
  const { data: stores, isLoading } = useGetBusinesses();

  return (
    <div>
      {isLoading ? (
        <div className="loader-container">
          <MagnifyingGlass
            visible={true}
            height="100"
            width="100"
            ariaLabel="magnifying-glass-loading"
            wrapperStyle={{}}
            wrapperclassName="magnifying-glass-wrapper"
            glassColor="#c0efff"
            color="#1A80E5"
          />
        </div>
      ) : (
        <>
          <ScrollToTop />
          <div>
            {/* Breadcrumb */}

            {/* Stores Section */}
            <section className="mt-8">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="bg-light rounded-3 d-flex justify-content-between">
                      <div className="d-flex align-items-center p-10">
                        <Slide direction="down">
                          <h1 className="mb-0 fw-bold">Tiendas</h1>
                        </Slide>
                      </div>
                      <div className="p-6">
                        <Zoom>
                          <img
                            src={graphics}
                            alt="gráficos"
                            className="img-fluid"
                          />
                        </Zoom>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            {/* Single Store Example */}
            <section className="mt-6 mb-lg-14 mb-8">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="mb-4">
                      <h6>
                        Tenemos{" "}
                        <span className="text-primary">
                          {Array.isArray(stores) && stores.length}
                        </span>{" "}
                        proveedor(es) ahora
                      </h6>
                    </div>
                  </div>
                </div>

                {/* Store Card */}
                <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4 g-lg-4">
                  {Array.isArray(stores) && stores.length > 0 ? (
                    stores.map((store, index) => (
                      <BusinessCard store={store} index={index} key={index} />
                    ))
                  ) : (
                    <p>No hay tiendas disponibles.</p>
                  )}
                </div>
              </div>
            </section>

            {/* Recently Viewed Section */}
            <section className="mb-lg-14 mb-8">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="mb-6">
                      <h3 className="mb-0">Vistas recientemente</h3>
                    </div>
                  </div>
                </div>
                <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4 g-lg-4">
                  {Array.isArray(stores) && stores.length > 0 ? (
                    stores.map((store, index) => (
                      <BusinessCard store={store} index={index} key={index} />
                    ))
                  ) : (
                    <p>No hay tiendas vistas recientemente.</p>
                  )}
                </div>
              </div>
            </section>
          </div>
        </>
      )}
    </div>
  );
};

export default StoreList;
