import slider1 from "../images/slide-1.png";
import slider2 from "../images/slide-2.png";

import { useEffect, useState } from "react";
import { MagnifyingGlass } from "react-loader-spinner";
import { Link } from "react-router-dom";
import { AdSection } from "../Component/AdSection";
import { AllySection } from "../Component/AllySection";
import { CategoryCircle } from "../Component/CategoryCircle";
import { AltokeSection } from "../Component/AltokeSection";
import { useGetCategories } from "../hooks/categories-hooks";

const Home = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  const { data: categories, isLoading } = useGetCategories();

  return (
    <div>
      <div>
        {isLoading ? (
          <div className="loader-container">
            {/* <PulseLoader loading={loaderStatus} size={50} color="#1A80E5" /> */}
            <MagnifyingGlass
              visible={true}
              height="100"
              width="100"
              ariaLabel="magnifying-glass-loading"
              wrapperStyle={{}}
              wrapperclassName="magnifying-glass-wrapper"
              glassColor="#c0efff"
              color="#1A80E5"
            />
          </div>
        ) : (
          <>
            <>
              <div className="scroll-to-top">
                <button
                  onClick={scrollToTop}
                  className={`scroll-to-top-button ${isVisible ? "show" : ""}`}
                >
                  ↑
                </button>
              </div>
              <section className="hero-section">
                <div
                  className="container-fluid p-0"
                  style={{ overflow: "hidden" }}
                >
                  <div
                    id="carouselExampleFade"
                    className="carousel slide carousel-fade"
                    data-bs-ride="carousel"
                  >
                    <div className="carousel-inner">
                      <div className="carousel-item active">
                        <img
                          src={slider1}
                          alt="Slide 1"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover", // o "contain" según prefieras
                            borderRadius: ".5rem",
                          }}
                        />
                      </div>
                      <div className="carousel-item">
                        <img
                          src={slider2}
                          alt="Slide 2"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover", // o "contain" según prefieras
                            borderRadius: ".5rem",
                          }}
                        />
                      </div>
                    </div>
                    <Link
                      className="carousel-control-prev"
                      to="#carouselExampleFade"
                      role="button"
                      data-bs-slide="prev"
                    >
                      <span
                        className="carousel-control-prev-icon"
                        aria-hidden="true"
                      />
                      <span className="visually-hidden">Previous</span>
                    </Link>
                    <Link
                      className="carousel-control-next"
                      to="#carouselExampleFade"
                      role="button"
                      data-bs-slide="next"
                    >
                      <span
                        className="carousel-control-next-icon"
                        aria-hidden="true"
                      />
                      <span className="visually-hidden">Next</span>
                    </Link>
                  </div>
                </div>
              </section>
            </>
            <>
              {/* section category */}
              <section className="mt-8">
                <div className="container">
                  <div className="row">
                    <div className="col-12">
                      <div className="mb-6">
                        <div className="section-head text-center mt-8">
                          <h3
                            className="h3style"
                            data-title="Shop Popular Categories"
                          >
                            Categorías
                          </h3>
                          <div className="wt-separator bg-primarys"></div>
                          <div className="wt-separator2 bg-primarys"></div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      {Array.isArray(categories) && categories.length > 0 ? (
                        categories.map((category, index) => (
                          <CategoryCircle
                            key={index}
                            name={category.name}
                            imageUrl={category.imageUrl}
                            id={category.id}
                          />
                        ))
                      ) : (
                        <div className="col-12 text-center">
                          <p>No hay categorías disponibles.</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </section>
              {/* section */}
            </>
            <AdSection />
            <AllySection />
            <AltokeSection />
          </>
        )}
      </div>
    </div>
  );
};

export default Home;
