import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import bloglarge from "../../images/Pizzadolce1.png";
import { MagnifyingGlass } from "react-loader-spinner";
import { Fade, Slide } from "react-awesome-reveal";
import { useRecoilState } from "recoil"; // Usamos Recoil para el estado global
import { cartState } from "../Shop/cartState"; // Importamos el estado atómico del carrito
import ScrollToTop from "../ScrollToTop";
import { useGetBusiness } from "../../hooks/business-hooks";
import { renderProductCard } from "../../utils/render-utils";
import { useNavigate } from "react-router-dom";

const Blog = () => {
  const [cart, setCart] = useRecoilState(cartState); // Usamos Recoil para el carrito
  const navigate = useNavigate();

  const handleAddToCart = (product) => {
    // Verificar si el producto ya está en el carrito
    const existingProduct = cart.find((item) => item.id === product.id);
    if (existingProduct) {
      // Si el producto ya está en el carrito, incrementar la cantidad
      setCart(
        cart.map((item) =>
          item.id === product.id
            ? { ...item, quantity: item.quantity + 1 }
            : item
        )
      );
    } else {
      // Si no está en el carrito, agregarlo
      setCart([...cart, { ...product, quantity: 1 }]);
    }

    // Guardar el carrito en localStorage para persistencia
    localStorage.setItem("cart", JSON.stringify(cart));

    navigate("/ShopCart"); // Redirige al carrito después de agregar
  };

  // Cargar los productos de la empresa
  const { id } = useParams();
  const { data: business, isLoading } = useGetBusiness({
    businessId: id,
    includeProducts: true,
  });

  return (
    <div>
      <div>
        {isLoading ? (
          <div className="loader-container">
            <MagnifyingGlass
              visible={true}
              height="100"
              width="100"
              ariaLabel="magnifying-glass-loading"
              wrapperStyle={{}}
              wrapperclassName="magnifying-glass-wrapper"
              glassColor="#c0efff"
              color="#1A80E5"
            />
          </div>
        ) : (
          <>
            <ScrollToTop />
            <div>
              <section className="mt-6 mb-lg-14 mb-8">
                <div className="container">
                  <div className="row d-flex align-items-center mb-8">
                    <div className="col-12 col-md-12 col-lg-8">
                      <Link to="#!">
                        <Fade>
                          <div className="img-zoom">
                            <img
                              src={bloglarge}
                              alt="blog"
                              className="img-fluid rounded-3 w-100"
                            />
                          </div>
                        </Fade>
                      </Link>
                    </div>
                    <div className="col-12 col-md-12 col-lg-4">
                      <Slide direction="down">
                        <div className="ps-lg-8 mt-8 mt-lg-0">
                          <h2 className="mb-3">
                            <p className="text-inherit">
                              {business?.name || "..."}
                            </p>
                          </h2>
                          <p>{business?.description || "..."}</p>
                          <div className="d-flex justify-content-between text-muted">
                            <span>
                              <small>{business?.address || "..."}</small>
                            </span>
                            <span>
                              <small>
                                Ciudad:{" "}
                                <span className="text-dark fw-bold">
                                  {business?.city || "Celendín"}
                                </span>
                              </small>
                            </span>
                          </div>
                        </div>
                      </Slide>
                    </div>
                  </div>
                  {!business.isOpen ? (
                    <div
                      className="bg-danger text-white text-center fs-3 fw-bold position w-75 mx-auto px-1"
                      style={{ borderRadius: "0px 100px" }}
                    >
                      Cerrado por hoy
                    </div>
                  ) : (
                    <p></p>
                  )}
                  <div className="row g-4 row-cols-xl-4 row-cols-lg-3 row-cols-2 row-cols-md-2 mt-2">
                    {business?.products?.map((product) => {
                      return renderProductCard({
                        product: product,
                        categoryKey: business.categoryKey || "OTHERS",
                        handleAddToCart: handleAddToCart,
                        canBuy: business.isOpen,
                      });
                    })}
                  </div>
                </div>
              </section>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
export default Blog;
